<template>
  <div :class="$store.getters['layout/getInnerWidth'] >= 991 ? '' : 'text-left'">
    <template v-for="(btn, i) in item.actions">
      <span
        :key="'data-table-index' + i"
        @click.stop="onActionClick (btn, item)"
        :class="isBusy === btn ? '' : 'pointer icon-custom-size'"
      >
       <vs-radio
         v-if="btn === 'radio'"
         v-model="selected"
         vs-name="radio"
         color="primary"
         @change="onRadio(item)"
       />
        <vs-checkbox
          v-if="btn === 'checkbox' && item.checked !== null"
          v-model="item.checked"
          vs-name="checkbox"
          color="primary"
          @change="onCheckBox(item)"
        />


        <template v-else>
          <i v-if="isBusy !== btn" :class="getBtnIconClass(btn)"></i>
          <i v-else class="btn-edit-size fa fa-spinner fa-spin"></i>
        </template>
      </span>
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    isBusy: '',
    selected: '',
  }),
  props: {
    //to do show spinner after confirm click
    isDeleting: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: this,
    },
  },
  beforeDestroy() {
    this.isBusy = '';
  },
  methods: {
    onRadio(item) {
      this.$emit('on:data-table-btn:radio', item);
    },
    onCheckBox(item) {
      this.$emit('on:data-table-btn:checkbox', item);
    },
    onActionClick(btn, item) {
      if (btn !== 'radio' && btn !== 'checkbox') {
        if (btn === 'delete') {
          if (this.isBusy === '') {
            this.$emit('on:data-table-btn:' + btn, item);
          }
        } else {
          this.$emit('on:data-table-btn:' + btn, item);
        }
      }
    },
    getBtnIconClass(btn) {
      switch (btn) {
        case 'show': {
          return 'btn-close-size icofont icofont-eye-alt'
        }
        case 'delete': {
          return 'btn-close-size icofont icofont-close'
        }
        case 'edit': {
          return 'btn-edit-size fa fa-pencil'
        }
        case 'history': {
          break;
        }
      }
    },
  },
  created() {
    this.$vs.theme({
      primary: '#0B2772'
    })
  }
}
</script>
<style lang="scss" scoped>
.btn-edit-size {
  font-size: 18px;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}

.btn-close-size {
  font-size: 20px;
}

.icon-custom-size {
  padding: 0.155rem 0.35rem;
  color: #919194;
  opacity: 1;
}

.icon-custom-size:hover {
  color: #165873;
}
</style>
